import React, { useState, useMemo, useCallback } from 'react'
import { useRobotsList } from '@src/logic/contexts/Robots/RobotsListsContext'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { LxClickAbleIcon } from '@src/components/icon/clickAbleIcon'
import { LxSelect } from '@components/select/select'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner'
import { isNilOrEmpty } from '@utils/isNilOrEmpty'
import { formatDate } from '@utils/dateFormatter'
import Tooltip from '@mui/material/Tooltip';
import styles from './RobotList.module.scss'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import RobotVersionInfo from './RobotVersionInfo'
import { AssignRobot } from './AssignRobot'
import RobotVoiceInfo from './RobotVoiceInfo'
import { cn } from '@utils/cn'
import { QtIcon } from '@icons/index'
import { LxIcon } from '@components/icon/Icon.tsx'
import { SearchIcon, FilterIcon,  PlusOutlineIcon } from '@icons/utils'
import { getVoiceLabel } from '@utils/voiceMapper'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { FilterObject, generateSimpleOptions } from '@src/logic/useFiltering.hook'
import { useAccountsList } from '@src/logic/contexts/Accounts/AccountsListsContext'
import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNotify } from 'react-admin'

type DataSet = {
  accounts?: FilterObject<AccountF>[]
}

export const RobotList = () => {
  const { robotList, isLoading } = useRobotsList()
  const [searchInput, setSearchInput] = useState('')
  const [filterType, setFilterType] = useState('all')
  const [sortConfig, setSortConfig] = useState(null)
  const { showModal, hideModal } = useModal()
  const { accountList, isLoading: isAccountsLoading } = useAccountsList()
  const notify = useNotify()
  
  const options: DataSet = useMemo(
    () => ({
      accounts: generateSimpleOptions(accountList, 'accountName'),
    }),
    [accountList, isAccountsLoading]
  )

  const [modalState, setModalState] = useState({
    open: false,
    robot: null,
    info: null,
    type: '',
  })

  const handleSearchAndFilter = useCallback((inputValue, filterValue) => {
    setSearchInput(inputValue)
    setFilterType(filterValue)
  }, [])

  // Utility function to get nested property
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj)
  }

  const handleSort = useCallback((key) => {
    setSortConfig((prev) => {
      const direction = prev && prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
      return { key, direction }
    })
  }, [])

  const openModal = useCallback((type, info, id, serial) => {
    setModalState({ open: true, type, info: { ...info, id, serial } })
  }, [])

  const closeModal = useCallback(() => {
    setModalState({ open: false, type: '', info: null })
  }, [])

  const filteredAndSortedData = useMemo(() => {
    let data = robotList

    // Search and filter robots
    if (searchInput) {
      const searchLower = searchInput.toLowerCase()
      data = data?.filter(
        (robot) =>
          robot.serial.toLowerCase().includes(searchLower) ||
          (robot.accountName && robot.accountName.toLowerCase().includes(searchLower)) ||
          (robot.ownerAccountName && robot.ownerAccountName.toLowerCase().includes(searchLower))
      )
    }
    if (filterType === 'Unassigned') {
      data = data.filter((robot) => !robot.accountId)
    }

    // Apply sorting with handling for null or undefined values
    if (sortConfig) {
      data = [...data].sort((a, b) => {
        const valA = getNestedValue(a, sortConfig.key)
        const valB = getNestedValue(b, sortConfig.key)

        // Special case for dates
        if (sortConfig.key === 'usageInfo.lastActivityDate') {
          const dateA = new Date(valA || 0) // Treat null or undefined as Jan 1, 1970
          const dateB = new Date(valB || 0)
          return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA
        }

        // Handle other data types, ensuring null or undefined values are sorted last in ascending order

        if (valA == null && valB == null) return 0
        if (valA == null) return sortConfig.direction === 'asc' ? 1 : -1
        if (valB == null) return sortConfig.direction === 'asc' ? -1 : 1

        if (valA < valB) return sortConfig.direction === 'asc' ? -1 : 1
        if (valA > valB) return sortConfig.direction === 'asc' ? 1 : -1
        return 0 // Handles equal values
      })
    }
    return data
  }, [robotList, searchInput, filterType, sortConfig])

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify('Copied ID clipboard - ' + text) 
      })
      .catch((err) => {
        notify('Failed to Copy ID clipboard - ' + err) 
      })
  }

  return (
    <>
      <div className={styles.robotTableNavigation}>
        <div className={styles.robotsListHeading}>
          <LxIcon icon={QtIcon} sxStyles={{ height: '80px', width: '80px', marginTop: '-18px' }} />
          <h3>Robots {!isLoading && ` (${filteredAndSortedData?.length})`} </h3>
        </div>

        <div className={sharedStyles.tableActions}>
          <div className={'lxActionButton lxActionButtonDefaultSize'}>
            <LxIcon icon={SearchIcon} />
            <input
              className={'pristineInput'}
              type='text'
              onChange={(e) => handleSearchAndFilter(e.target.value, filterType)}
              placeholder='Search by Serial, Account Name'
            />
          </div>

          <LxSelect
            shrinked
            icon={<LxIcon icon={FilterIcon} sxStyles={{ height: '26px', width: '32px' }} />}
            placeholder={filterType}
            onChange={(value) => handleSearchAndFilter(searchInput, value)}
            options={[
              { value: 'All Robots', label: 'All Robots' },
              { value: 'Unassigned', label: 'Unassigned' },
            ]}
          />
        </div>
      </div>

      <div className={styles.robotsListContent}>
        <div className={cn(styles.robotsTableListHeader, styles.robotName)} onClick={() => handleSort('serial')}>
          Serial No.
        </div>

        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide2column)} onClick={() => handleSort('ownerAccountName')}>
          Deligator
        </div>
        <div className={cn(styles.robotsTableListHeader)} onClick={() => handleSort('accountName')}>
          Assigned
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide3column)} onClick={() => handleSort('usageInfo.lastActivityDate')}>
          Last Activity
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide4column)} onClick={() => handleSort('creationInfo.userName')}>
          Created By
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide5column)} onClick={() => handleSort('versionInfo.softwareVersion')}>
          Version Info
        </div>
        <div className={cn(styles.robotsTableListHeader, sharedStyles.hide6column)} onClick={() => handleSort('configuration.voice.default')}>
          Voices
        </div>

        {isLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : isNilOrEmpty(filteredAndSortedData) ? (
          <div className={sharedStyles.notFound}>No robot matches your current search.</div>
        ) : (
          filteredAndSortedData.map((robot) => (
            <React.Fragment key={robot.id}>
              <div>
                <Tooltip 
                  title={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px', maxWidth: '300px' }}>
                      <span style={{ wordWrap: 'break-word', flex: 1 }}>{robot.id}</span>
                      <ContentCopyIcon
                        onClick={() => handleCopy(robot.id)}
                        style={{
                          cursor: 'pointer',
                          fontSize: '18px',
                          color: '#4a90e2',
                        }}
                      />
                    </div>
                  }
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                  }}
                >
                {robot.serial} 
                </Tooltip>
              </div>

              <div className={cn(styles.noCursor, sharedStyles.hide2column)}>{robot.ownerAccountName ? robot.ownerAccountName : '-'}</div>
              <div className={cn({ [styles.noCursor]: robot.accountName && robot.accountName.trim() })}>
                {robot.accountName && robot.accountName.trim() ? (
                  robot.accountName
                ) : (
                  <LxTooltip tooltipText={'Robot is not assigned, Click to assign'}>
                    <LxClickAbleIcon
                      className={cn(styles.activityAction, styles.createNewGoalAction)}
                      icon={PlusOutlineIcon}
                      onClick={() => showModal(<AssignRobot robot={robot} onClose={hideModal} accounts={options.accounts} />)}
                    />
                  </LxTooltip>
                )}
              </div>
              <div className={cn(styles.noCursor, sharedStyles.hide3column)}>
                {robot.usageInfo?.lastActivityDate ? formatDate(robot.usageInfo?.lastActivityDate) : 'N/A'}
              </div>
              <div className={cn(sharedStyles.hide4column)}>
                <LxTooltip tooltipText={formatDate(robot.creationInfo.date)}>{robot.creationInfo?.userName}</LxTooltip>
              </div>
              <div onClick={() => openModal('version', robot.versionInfo, robot.id, robot.serial)} className={cn(sharedStyles.hide5column)}>
                {robot.versionInfo?.softwareVersion}
              </div>
              <div onClick={() => openModal('voice', robot.configuration?.voice, robot.id, robot.serial)} className={cn(sharedStyles.hide6column)}>
                {getVoiceLabel(robot.configuration?.voice?.default)}
              </div>
            </React.Fragment>
          ))
        )}
      </div>

      <RobotVersionInfo
        versionInfo={modalState.type === 'version' && modalState.info}
        open={modalState.open && modalState.type === 'version'}
        onClose={closeModal}
      />
      <RobotVoiceInfo
        voiceInfo={modalState.type === 'voice' && modalState.info}
        open={modalState.open && modalState.type === 'voice'}
        onClose={closeModal}
      />
    </>
  )
}

export default RobotList
