import { SessionPlanOutlineIcon } from '@icons/index.ts'
import { SearchIcon } from '@icons/utils'
import React, { useState, useEffect } from 'react'
import { useSubscriptionsList } from '@src/logic/contexts/Subscriptions/SubscriptionsListsContext'
import styles from './SubscriptionList.module.scss'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { cn } from '@src/utils/cn.ts'
import { SubscriptionF } from '@logic/contexts/Subscriptions/SubscriptionsFrontend.type.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty'
import { formatDate } from '@src/utils/dateFormatter'

export const SubscriptionList = () => {
  const { subscriptionList, isLoading } = useSubscriptionsList()
  const [filteredData, setFilteredData] = useState<SubscriptionF[]>(subscriptionList)
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null)

  const handleSearchInputChange = (inputValue: string) => {
    const filteredSubscriptions = subscriptionList?.filter((subscription) =>
      subscription.accountName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredData(filteredSubscriptions)
  }

  // Sorting handler
  const handleSort = (key: keyof SubscriptionF) => {
    let direction: 'asc' | 'desc' = 'asc'

    // Toggle the sort direction
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }

    setSortConfig({ key, direction })

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1
      return 0
    })

    setFilteredData(sortedData)
  }

  useEffect(() => {
    if (!isLoading) {
      setFilteredData(subscriptionList)
    }
  }, [isLoading, subscriptionList])

  return (
    <>
      <div className={styles.subscriptionTableNavigation}>
        <div className={styles.subscriptionsListHeading}>
          <LxIcon sxStyles={{ height: '80px', width: '80px', marginTop: '-18px' }} icon={SessionPlanOutlineIcon} />
          <h3>Subscriptions  {!isLoading && ` (${filteredData?.length})`}</h3>
        </div>
        <div className={sharedStyles.tableActions}>
          {/* <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
            <Link to={'create'}>
              <LxIcon icon={PlusIcon} />
              Add New Subscription
            </Link>
          </div> */}
          <div className={'lxActionButton lxActionButtonDefaultSize'}>
            <LxIcon icon={SearchIcon} />
            <input
              className={'pristineInput'}
              type='text'
              onChange={(e) => {
                handleSearchInputChange(e.target.value)
              }}
              placeholder='Search by Customer Name'
            />
          </div>
        </div>
      </div>
      <div className={styles.subscriptionsListContent}>
        <div className={cn(styles.subscriptionsTableListHeader, styles.subscriptionName)} onClick={() => handleSort('accountName')}>
          Account Name
        </div>
        <div className={cn(styles.subscriptionsTableListHeader, styles.notSortable, sharedStyles.hide2column)}>Customer Id</div>
        <div className={cn(styles.subscriptionsTableListHeader, sharedStyles.hide3column)} onClick={() => handleSort('subscriptionTypId')}>
          Subscription Type
        </div>
        <div className={cn(styles.subscriptionsTableListHeader, sharedStyles.hide4column)} onClick={() => handleSort('startDate')}>
          Start Date
        </div>
        <div className={cn(styles.subscriptionsTableListHeader)} onClick={() => handleSort('endDate')}>
          End Date
        </div>
        <div className={cn(styles.subscriptionsTableListHeader, styles.notSortable, sharedStyles.hide5column)}>Purchased Licenses</div>
        <div className={cn(styles.subscriptionsTableListHeader, styles.notSortable, sharedStyles.hide6column)}>Used Licenses</div>
        <div className={cn(styles.subscriptionsTableListHeader, styles.notSortable, sharedStyles.hide7column)}>Payment Method</div>
        <div className={cn(styles.subscriptionsTableListHeader, styles.notSortable, sharedStyles.hide8column)}>Status</div>
        {isLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : isNilOrEmpty(filteredData) ? (
          <div className={sharedStyles.notFound}>No subscription match your current search.</div>
        ) : (
          filteredData.map((subscription: SubscriptionF) => (
            <React.Fragment key={subscription.id}>
              <div className={styles.noCursor}>{subscription.accountName}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide2column)}>{subscription.customerId}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide3column)}>{subscription.subscriptionTypId}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide4column)}>{formatDate(subscription.startDate)}</div>
              <div className={cn(styles.noCursor)}>{formatDate(subscription.endDate)}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide5column)}>{subscription.nfPurchasedLicenses}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide6column)}>{subscription.nfUsedLicenses}</div>
              <div className={cn(styles.noCursor, sharedStyles.hide7column)}>
                {subscription.paymentMethod.charAt(0).toUpperCase() + subscription.paymentMethod.slice(1)}
              </div>
              <div className={cn(styles.noCursor, sharedStyles.hide8column)}>
                {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
              </div>
            </React.Fragment>
          ))
        )}
      </div>
    </>
  )
}

export default SubscriptionList
