import React, { FC, useEffect } from 'react'
import styles from '../UserAddNewLearner.module.scss'
import selectStyles from '@components/select/select.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxSelectInput } from '@components/select/selectInput.tsx'
import { ToggleButton } from '@components/toggleButton/toggleButton.tsx'
import { useForm, Controller } from 'react-hook-form'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { InfoOutlineIcon } from '@icons/utils'
import { LxIcon } from '@components/icon/Icon.tsx'
import { UserData } from '@src/pages/Learner/Show/TeamMembers/LearnerMembers.type.ts'
import { FilterObject } from '@logic/useFiltering.hook.ts'

import { useDataProvider, useNotify, useRefresh } from 'react-admin'
import usePrevious from '@src/hooks/usePrevious.hook.ts'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import modalStyles from '@components/modal/modal.module.scss'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

interface Props {
  onClose: () => void
  users: UserData
  learnerId: string
  learnerFullName: string
}

const schema = yup
  .object()
  .shape({
    selectedLearner: yup.object().required('Selecting a learner is required.'),
    canRunActivities: yup.boolean(),
  })
  .required()

const getDefaultFormProps = (
  selectedLearner: string | null
) => {
  return {
    canRunActivities: true,
    selectedLearner: selectedLearner,

  }
}

export const AddNewUserLearner: FC<Props> = ({
                                               onClose,
                                               learners,
                                               userId,
                                               userFullName,
                                               userRole,
                                               superVisor,
                                             }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const {isFormSubmitting, setIsFormSubmitting} = useSubmitLoader();

  const {
    reset,
    control,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultFormProps(null),
    resolver: yupResolver(schema),
  })

  const prevSelectedLearnerId = usePrevious(watch('selectedLearner')?.value.id)

  // Transform mockedUsers into an array of FilterObject, data structure used by LxSelectInput
  const allLearners: FilterObject<UserData>[] = learners.map((learner) => ( {
    value: learner,
    isEnabled: true,
    label: learner.fullName,
    optionToRender: (
      <div className={selectStyles.selectInputOptionTwoColumn}>
        <span>{learner.fullName}</span>
      </div>
    ),
    id: learner.id,
  } ))

  const selectedLearner = watch('selectedLearner')

  useEffect(() => {
    if (prevSelectedLearnerId !== selectedLearner?.value.id) {
      clearErrors('selectedLearner')
      reset(getDefaultFormProps(selectedLearner))
    }
  }, [ reset, prevSelectedLearnerId, selectedLearner?.value.id ])


  // canRunActivities - "AC-2310-UUL-004"
  // canViewAllGoals - "AC-2310-UL-002"
  // canEditDeleteAllGoals - "AC-2310-UL-003"

  const onSubmit = async (formData) => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    try {
      const { selectedLearner, canRunActivities } = formData

      const accesses = []

      if (userRole === 'Assistant') {
        if (canRunActivities) {
          accesses.push('AC-2310-UUL-004')
        }
      }

      const payload = {
        assign_learner: {
          learner_id: selectedLearner.id,
          supervisor_id: superVisor.id,
          accesses: accesses,
          action: 'add_learner',
        },
      }

      await dataProvider.update('User', {
        id: userId,
        data: payload,
        previousData: null,
      })
      // Handle success case
      notify('Learener added successfully', { type: 'success' })
    } catch (error) {
      // Handle error case
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    }  finally {
      refresh()
      onClose()
      setIsFormSubmitting(false);
    }
  }

  return (
    <div className={modalStyles.modalMd}>
       {isFormSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}
      
      <div className={modalStyles.dialogHeader}>
        <h3>Assign a new learner to {userFullName} </h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="selectedLearner"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LxSelectInput
                className={styles.formField}
                value={value}
                hasError={!!error}
                onReset={() => reset(getDefaultFormProps(null))}
                onChange={onChange}
                options={allLearners}
                placeholder={'Select Learner'}
              />
            )}
          />
          <>
            <div className={styles.optionalCheckboxRow}>
              <span>Can enroll in curriculums and run un-planned activities for the selected learner</span>
              <LxTooltip
                tooltipText={
                  'Assistant can also run activities that are not pre-planned by the supervisor and the enroll the learner in new curriculums'
                }
              >
                <LxIcon icon={InfoOutlineIcon}/>
              </LxTooltip>
              <Controller
                name="canRunActivities"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ToggleButton value={value} onChange={onChange}/>
                )}
              />
            </div>
          </>
          {errors.selectedLearner && (
            <p className={styles.error}>{errors.selectedLearner.message}</p>
          )}
        </form>
      </div>
      <div className={modalStyles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onClose}
        >
          <span>Cancel</span>
        </div>
        <div
          className={cn(
            'lxActionButton lxActionButtonFilled lxActionButtonDefaultSize'
          )}
          onClick={handleSubmit(onSubmit)}
        >
          <span>Assign</span>
        </div>
      </div>
    </div>
  )
}
